import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
let routes = [];

routes.push({
    path: "/",
    name: "Home",
    component: () =>
        import ("../mvp/landing"),
});

routes.push({
    path: "/ugc",
    name: "ugc",
    component: () =>
        import ("../mvp/file_processors/ugc"),
});

routes.push({
    path: "/eye-tracking/sequence",
    name: "EyeTrackingSequence",
    component: () =>
        import ("../mvp/eyetracking/eyetracking_sequence"),
});

routes.push({
    path: "/eye-tracking/master",
    name: "EyeTrackingV4",
    component: () =>
        import ("../mvp/eyetracking/eyetracking_v4"),
});

routes.push({
    path: "/eye-tracking/v5",
    name: "EyeTrackingV5",
    component: () =>
        import ("../mvp/eyetracking/eyetracking_v5"),
});

routes.push({
    path: "/eye-tracking/v1",
    name: "EyeTrackingV1",
    component: () =>
        import ("../mvp/eyetracking/eyetracking_v1"),
});

routes.push({
    path: "/eye-tracking/v2",
    name: "EyeTrackingV2",
    component: () =>
        import ("../mvp/eyetracking/eyetracking_v2"),
});

routes.push({
    path: "/eye-tracking/v3",
    name: "EyeTrackingV3",
    component: () =>
        import ("../mvp/eyetracking/eyetracking_v3"),
});

routes.push({
    path: "/llama",
    name: "LLaMA",
    component: () =>
        import ("../mvp/llamaEdit"),
});

routes.push({
    path: "/smm",
    name: "SMM",
    component: () =>
        import ("../mvp/smm"),
});

routes.push({
    path: "/smm-service",
    name: "SMM",
    component: () =>
        import ("../mvp/smm-service"),
});

routes.push({
    path: "/smm_lk",
    name: "SMM_LK",
    component: () =>
        import ("../mvp/smm_lk"),
});

routes.push({
    path: "/smm-chain",
    name: "SMM-chain",
    component: () =>
        import ("../mvp/smm-chain"),
});

routes.push({
    path: "/smm-admin",
    name: "SMM_admin",
    component: () =>
        import ("../mvp/smm-admin.vue"),
});

routes.push({
    path: "/smm-history",
    name: "SMM_history",
    component: () =>
        import ("../mvp/smm-history.vue"),
});

routes.push({
    path: "/hallucinations-fix",
    name: "LLM Hallucinations fix",
    component: () =>
        import ("../mvp/hallucinations/hallucinations-chain.vue"),
});

routes.push({
    path: "/spellchecker",
    name: "SMM",
    component: () =>
        import ("../mvp/spellchecker"),
});

routes.push({
    path: "/avatars",
    name: "Pushka Avarats",
    component: () =>
        import ("../mvp/avatars"),
});


routes.push({
    path: "/wordcloud/:stream",
    name: "WordCould",
    component: () =>
        import ("../mvp/wordcloud-base"),
});

routes.push({
    path: "/wordcloud-nospellcheck-ba-dedup",
    redirect: "/wordcloud/SberBA",
});

routes.push({
    path: "/wordcloud-nospellcheck-ba-macro-dedup",
    redirect: "/wordcloud/MacroBA",
});

routes.push({
    path: "/auth/login",
    name: "login",
    component: () =>
        import ("../mvp/auth/login"),
});
routes.push({
    path: "/login",
    redirect: "/auth/login",
});

routes.push({
    path: "/auth/signup",
    name: "signup",
    component: () =>
        import ("../mvp/auth/signup"),
});
routes.push({
    path: "/auth/recover-password",
    name: "recover-password",
    component: () =>
        import ("../mvp/auth/recover-password"),
});
routes.push({
    path: "/auth/reset-password/:username/:token",
    name: "EnterNewPassword",
    component: () =>
        import ("../mvp/auth/enter-new-password"),
});
routes.push({
    path: "/auth/account-confirmed/:email",
    name: "AccountConfirmed",
    component: () =>
        import ("../mvp/auth/account-confirmed"),
});

routes.push({
    path: "/real_time_ners",
    name: "SML_analytics_ner_natasha_nospellcheck_dedup",
    component: () =>
        import ("../mvp/analytics/real_time_ners_nospellcheck_dedup"),
});
routes.push({
    path: "/n1-ners",
    name: "N1 ners nospellcheck",
    component: () =>
        import ("../mvp/n1-ners-nospellcheck"),
});

routes.push({
    path: "/sopelka",
    name: "Sopelka",
    component: () =>
        import ("../mvp/file_processors/sopelka"),
});

routes.push({
    path: "/chastotka",
    name: "Chastotka",
    component: () =>
        import ("../mvp/file_processors/chastotka"),
});

routes.push({
    path: "/kruchevo",
    name: "Kruchevo",
    component: () =>
        import ("../mvp/file_processors/kruchevo"),
});

routes.push({
    path: "/object-sentiment-emotion-24h",
    name: "EmotionObjectSentiment24h",
    component: () =>
        import ("../mvp/object-sentiment/emotion24h"),
});

routes.push({
    path: "/object-role-24h",
    name: "ObjectRole24h",
    component: () =>
        import ("../mvp/object-role/role24h"),
});

routes.push({
    path: "/object-sentiment-emotion-role-24h",
    name: "EmotionObjectSentimentRole24h",
    component: () =>
        import ("../mvp/object-sentiment/emotion_role24h"),
});

routes.push({
    path: "/object-sentiment-event-role-24h",
    name: "EventObjectSentimentRole24h",
    component: () =>
        import ("../mvp/object-sentiment/event_role24h"),
});

routes.push({
    path: "/vertelka",
    name: "Vertelka",
    component: () =>
        import ("../mvp/vertelka/vertelka"),
});

routes.push({
    path: "/8k-copywriting",
    name: "8k-copywriting",
    component: () =>
        import ("../mvp/8k/8k_copywriting"),
});

routes.push({
    path: "/search-sberpress",
    name: "SearchSberPress",
    component: () =>
        import ("../mvp/search-sberpress/search-sberpress"),
});

routes.push({
    path: "/search-news",
    name: "SearchNews",
    component: () =>
        import ("../mvp/search-news-alerts/search-news-results"),
});

routes.push({
    path: "/search-ba",
    name: "SearchBA",
    component: () =>
        import ("../mvp/search-news-alerts/search-ba-results"),
});

routes.push({
    path: "/topic-modelling",
    name: "TopicModelling",
    component: () =>
        import ("../mvp/topic-modelling/topic-modelling"),
});
routes.push({
    path: "/topic-modelling-rawdata",
    name: "TopicModellingRawdata",
    component: () =>
        import ("../mvp/topic-modelling/topic-modelling-rawdata"),
});
routes.push({
    path: "/topic-modelling/get-file/:id",
    name: "TopicModellingReport",
    component: () =>
        import ("../mvp/topic-modelling/get_file"),
});

// routes.push({
//     path: "/topic-modelling-live/clusters",
//     name: "TopicModellingLive",
//     component: () =>
//         import ("../mvp/topic-modelling-live/clusters"),
// });

routes.push({
    path: "/topic-modelling-live/history/:clusterization_id/:cluster_id/:stream",
    name: "TopicModellingHistory",
    component: () =>
        import ("../mvp/topic-modelling-live/history"),
});
routes.push({
    path: "/topic-modelling-live/bunches",
    name: "TopicModellingBunchFeed",
    component: () =>
        import ("../mvp/topic-modelling-live/bunchFeed"),
});

routes.push({
    path: "/topic-modelling-live/bunchHistory/:bunchId/:stream",
    name: "TopicModellingBunchHistory",
    component: () =>
        import ("../mvp/topic-modelling-live/bunchHistory"),
});
routes.push({
    path: "/topic-modelling-live/nerHistory/:neId/:stream",
    name: "TopicModellingNerHistory",
    component: () =>
        import ("../mvp/topic-modelling-live/nerHistory"),
});

routes.push({
    path: "/search-ba-test",
    name: "SearchBATest",
    component: () =>
        import ("../mvp/search-ba/search-ba"),
});
routes.push({
    path: "/search-ba-test/:search_hash/page/:page",
    name: "SearchBA-results-test",
    component: () =>
        import ("../mvp/search-ba/search-ba-results"),
});
routes.push({
    path: "/n1-ners",
    name: "N1 ners",
    component: () =>
        import ("../mvp/n1-ners"),
});

routes.push({
    path: "/ba-macro-download",
    name: "ba_macro_download",
    component: () =>
        import ("../mvp/ba_macro_download"),
});
routes.push({
    path: "/chatgpt",
    name: "ChatGPT",
    component: () =>
        import ("../mvp/ChatGPT"),
});
routes.push({
    path: "/speech2text/:hash",
    name: "speech2text",
    component: () =>
        import ("../mvp/analytics/whisper.vue"),
});
routes.push({
    path: "/speech2text-projects", //SBERSPEECH V1
    name: "Speech2textProjects",
    component: () =>
        import ("../mvp/analytics/whisper-projects.vue"),
});
routes.push({
    path: "/speech2text-history/:hash",
    name: "Speech2TextHistory",
    component: () =>
        import ("../mvp/analytics/whisper-history.vue"),
});
routes.push({
    path: "/transcribator", // WHISSPER V2
    name: "transcribator",
    component: () =>
        import ("../mvp/analytics/sound_from_file_to_text"),
});
routes.push({
    path: "/whisperx", // WHISPER X
    name: "WhisperxprojectsList",
    component: () =>
        import ("../mvp/analytics/whisperX-projects-list.vue"),
});
routes.push({
    path: "/whisperx/add-project/:hash", // WHISPER X
    name: "WhisperxAddProject",
    component: () =>
        import ("../mvp/analytics/whisperX-add-project"),
});
routes.push({
    path: "/whisperx/project/:hash", // WHISPER X
    name: "WhisperxProject",
    component: () =>
        import ("../mvp/analytics/whisperX-project"),
});
routes.push({
    path: "/whisperx/document/:hash", // WHISPER X
    name: "WhisperxDocument",
    component: () =>
        import ("../mvp/analytics/whisperX-document"),
});
routes.push({
    path: "/transcribator-result/:hash",
    name: "TranscribatorResult",
    component: () =>
        import ("../mvp/analytics/sound_from_file_to_text_results"),
});
//TBA WHISPER V3
routes.push({
    path: "/admin",
    name: "AdminPage",
    component: () =>
        import ("../mvp/admin/admin"),
});

routes.push({
    path: "/roi",
    name: "ROI",
    component: () =>
        import ("../mvp/test_streamlit"),
});

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;